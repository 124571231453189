import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Supportedby from "../components/supportedby";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";

const EventsCalendar = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				socialImage: file(relativePath: { eq: "hero-home-comp.jpg" }) {
					publicURL
				}
				coffeeImage1: wpMediaItem(
					title: { eq: "Eastbourne coffee morning 2024" }
				) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				coffeeImage2: wpMediaItem(title: { eq: "Coffee evening Jan-March" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				coffeeImage3: wpMediaItem(title: { eq: "Bexhill coffee morning" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				cakeAndConvo: wpMediaItem(title: { eq: "cake-and-convo" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				multiEvents: wpMediaItem(
					title: { eq: "Coffee Mornings and Evenings 2024" }
				) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				fundraisingDinner: wpMediaItem(title: { eq: "fundraising-dinner" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				valentines: wpMediaItem(title: { eq: "Valentines wrapping poster" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				easter: wpMediaItem(title: { eq: "Easter Fete Poster 2024" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				events2024: wpMediaItem(title: { eq: "2024 Events Poster" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				motherDay2024: wpMediaItem(title: { eq: "Brain Poster April 24" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				pageData: wpPage(slug: { eq: "events-calendar" }) {
					seoFields {
						title
						metaDescription
						opengraphTitle
						opengraphDescription
						localBusinessSchema
						image {
							node {
								sourceUrl
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
										original {
											width
											height
										}
									}
								}
							}
						}
					}
					eventsCalendarFields {
						eventsCalendarSection {
							eventsCalendarList {
								heading
								image {
									node {
										altText
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [AUTO, WEBP]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: CONTAIN }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
												original {
													width
													height
												}
											}
										}
									}
								}
							}
						}
						eventsSponsorsSection {
							heading
							sponsorItems {
								image {
									node {
										altText
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [AUTO, WEBP]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: CONTAIN }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
												original {
													width
													height
												}
											}
										}
									}
								}
								link {
									title
									target
									url
								}
							}
						}
						supportedGallerySection {
							heading
							imageGallery {
								nodes {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
											original {
												width
												height
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`
	);

	const {
		pageData: {
			seoFields,
			eventsCalendarFields: {
				eventsCalendarSection,
				eventsSponsorsSection,
				supportedGallerySection,
			},
		},
	} = data;

	const siteUrl = data.site.siteMetadata.siteUrl;
	const socialImg = data.socialImage.publcURL;
	const coffeeImage1 =
		data.coffeeImage1?.localFile.childImageSharp.gatsbyImageData;
	const coffeeImage2 =
		data.coffeeImage2?.localFile.childImageSharp.gatsbyImageData;
	const coffeeImage3 =
		data.coffeeImage3?.localFile.childImageSharp.gatsbyImageData;
	const multiEvents =
		data.multiEvents?.localFile.childImageSharp.gatsbyImageData;
	const fundraisingDinner =
		data.fundraisingDinner?.localFile.childImageSharp.gatsbyImageData;
	const easter = data.easter?.localFile.childImageSharp.gatsbyImageData;
	const events2024 = data.events2024?.localFile.childImageSharp.gatsbyImageData;
	const motherDay2024 =
		data.motherDay2024?.localFile.childImageSharp.gatsbyImageData;
	console.log(data);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Events",
				item: {
					url: `${siteUrl}/events-calendar`,
					id: `${siteUrl}/events-calendar`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/events-calendar`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: "Events Calendar Holding Space",
						},
					],
				}}
			/>

			{eventsCalendarSection && eventsCalendarSection.eventsCalendarList && (
				<Container className="pt-5">
					<Row className="g-3">
						{eventsCalendarSection.eventsCalendarList.map((item) => (
							<Col xs={12} md={6} lg={4} className="pb-5">
								{" "}
								<h5 className="text-center">{item?.heading}</h5>
								<GatsbyImage
									// style={{ height: "13rem", borderRadius: "0px" }}
									image={
										item?.image.node?.localFile.childImageSharp.gatsbyImageData
									}
									alt={item?.image.node?.altText}
								/>
							</Col>
						))}
					</Row>
				</Container>
			)}

			{/* <section className="py-5">
				<Container>
					<Row className="text-center">
						<Col>
							<iframe
								className="calendar"
								src="https://calendar.google.com/calendar/embed?src=hansa%40holdingspace.org.uk&ctz=Europe%2FLondon"
								frameborder="0"
								scrolling="no"
							></iframe>
						</Col>
					</Row>
				</Container>
			</section> */}

			<Supportedby
				title={supportedGallerySection?.heading}
				supportbyImages={supportedGallerySection?.imageGallery?.nodes}
				sponsorTitle={eventsSponsorsSection?.heading}
				sponsorLogos={eventsSponsorsSection?.sponsorItems}
			/>
		</Layout>
	);
};
export default EventsCalendar;
